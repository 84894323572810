import { IKLink } from '../shared/material';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className='bg-red py-6'>
      <div className='flex justify-center gap-6'>
        <span className='text-center text-xs leading-5 text-charcoal'>
          &copy; {currentYear} iKhokha. All Rights Reserved.
        </span>
        <IKLink
          link={
            {
              url: '/terms-and-conditions',
              type: 'internal',
            } as any
          }
          className='text-center text-xs leading-5 text-charcoal hover:underline'
        >
          Terms & Conditions
        </IKLink>
      </div>
    </footer>
  );
}
