import { getHomePageUrl } from '@/lib/pathUtils';

import { BackButton } from '@/components/purchase-journey';
import IKLink from '../shared/material/IKLink';
export default function Header() {
  return (
    <nav className='bg-white shadow'>
      <div className='mx-auto flex h-16 items-center justify-between px-4 sm:px-6 md:justify-center lg:px-8'>
        <div className='flex w-1/3 md:hidden'>
          <BackButton color={'charcoal'} text={''} />
        </div>
        <IKLink link={{ url: getHomePageUrl(), type: 'internal' } as any}>
          <svg
            id='Logo_-_Full_Logo_26px'
            data-name='Logo - Full Logo 26px'
            xmlns='http://www.w3.org/2000/svg'
            width='128.167'
            height='26'
            viewBox='0 0 128.167 26'
          >
            <path
              id='Path_24'
              data-name='Path 24'
              d='M1251.957,657.669a1.453,1.453,0,0,1-.429,1.245,1.67,1.67,0,0,1-1.065.276h-.609a3.143,3.143,0,0,1-2.005-.5,2.13,2.13,0,0,1-.6-1.714v-9.984a1.482,1.482,0,0,1,.416-1.245,1.781,1.781,0,0,1,1.079-.3l.608.028a3.15,3.15,0,0,1,2.006.5,2.134,2.134,0,0,1,.595,1.715Zm-4.2-17.659a2.786,2.786,0,0,1,3.705,0,2.572,2.572,0,0,1,0,3.568,2.786,2.786,0,0,1-3.705,0,2.572,2.572,0,0,1,0-3.568'
              transform='translate(-1217.96 -636.262)'
              fill='#1d1d1b'
            />
            <path
              id='Path_25'
              data-name='Path 25'
              d='M1266.73,653.455v5.2a1.517,1.517,0,0,1-.512,1.351,1.911,1.911,0,0,1-1.175.312l-.581-.029a2.977,2.977,0,0,1-2.019-.535,2.563,2.563,0,0,1-.58-1.93V643.508a1.542,1.542,0,0,1,.469-1.337,1.988,1.988,0,0,1,1.162-.327l.608.03a2.977,2.977,0,0,1,2.019.534,2.563,2.563,0,0,1,.581,1.93v4.721l5.061-6.266a1.743,1.743,0,0,1,1.438-.772,1.015,1.015,0,0,1,.332.03,3.075,3.075,0,0,1,1.8.831,2.25,2.25,0,0,1,.636,1.633,3.082,3.082,0,0,1-.346,1.44,7.459,7.459,0,0,1-1.175,1.559l-3.457,3.712,2.213,2.583.747.862,1.881,2.168a3.15,3.15,0,0,1,.47.683,1.526,1.526,0,0,1,.139.653,1.143,1.143,0,0,1-.11.535,2.3,2.3,0,0,1-.609.95,2.581,2.581,0,0,1-1.854.713,2.93,2.93,0,0,1-1.645-.445,7.5,7.5,0,0,1-1.59-1.6Z'
              transform='translate(-1224.879 -637.448)'
              fill='#1d1d1b'
            />
            <path
              id='Path_26'
              data-name='Path 26'
              d='M1306.19,658.854a1.422,1.422,0,0,1-.471,1.258,1.735,1.735,0,0,1-1.078.291l-.553-.028a3.144,3.144,0,0,1-2.005-.5,2.129,2.129,0,0,1-.594-1.714v-6.14q0-2.047-1.964-2.047a3.6,3.6,0,0,0-1.853.471v8.408a1.422,1.422,0,0,1-.47,1.258,1.738,1.738,0,0,1-1.079.291l-.553-.028a3.144,3.144,0,0,1-2.005-.5,2.131,2.131,0,0,1-.594-1.714V643.393a1.478,1.478,0,0,1,.415-1.244,1.78,1.78,0,0,1,1.079-.3l.609.028a3.145,3.145,0,0,1,2.005.5,2.13,2.13,0,0,1,.595,1.714V646.8a8.65,8.65,0,0,1,1.272-.346,7.207,7.207,0,0,1,1.438-.153,6.091,6.091,0,0,1,4.315,1.4,5.281,5.281,0,0,1,1.494,4.052Z'
              transform='translate(-1239.392 -637.448)'
              fill='#1d1d1b'
            />
            <path
              id='Path_27'
              data-name='Path 27'
              d='M1326.844,659.9a2.1,2.1,0,0,0,1.812.912,2.029,2.029,0,0,0,1.784-.912,4.534,4.534,0,0,0,.623-2.572,4.395,4.395,0,0,0-.636-2.558,2.248,2.248,0,0,0-3.6,0,5.441,5.441,0,0,0,.014,5.131m7.081,2.7a7.278,7.278,0,0,1-5.3,1.895,7.059,7.059,0,0,1-5.226-1.936,6.79,6.79,0,0,1-2.019-5.227,8,8,0,0,1,.525-2.945,6.368,6.368,0,0,1,1.494-2.282,7.239,7.239,0,0,1,5.226-1.909,8.437,8.437,0,0,1,2.932.5,6.526,6.526,0,0,1,2.324,1.438,6.245,6.245,0,0,1,1.479,2.282,8.129,8.129,0,0,1,.512,2.918,7.009,7.009,0,0,1-1.949,5.269'
              transform='translate(-1252.648 -641.342)'
              fill='#1d1d1b'
            />
            <path
              id='Path_28'
              data-name='Path 28'
              d='M1364.513,656.973a1.815,1.815,0,0,1,.636,1.244,1.648,1.648,0,0,1-.11.553,2.253,2.253,0,0,1-2.351,1.632,2.867,2.867,0,0,1-1.549-.415,6.779,6.779,0,0,1-1.521-1.466l-2.738-3.429v3.762a1.45,1.45,0,0,1-.429,1.244,1.663,1.663,0,0,1-1.064.277h-.609a3.148,3.148,0,0,1-2.005-.5,2.13,2.13,0,0,1-.595-1.715V643.42a1.494,1.494,0,0,1,.429-1.258,1.742,1.742,0,0,1,1.065-.318l.608.028a3.145,3.145,0,0,1,2.006.5,2.13,2.13,0,0,1,.594,1.714v7.3l4.038-4.149a1.848,1.848,0,0,1,1.328-.664l.415.055a3.191,3.191,0,0,1,1.576.788,1.812,1.812,0,0,1,.581,1.341,2.1,2.1,0,0,1-.346,1.2,6.6,6.6,0,0,1-1.2,1.231l-.83.719-1.6,1.383Z'
              transform='translate(-1267.016 -637.448)'
              fill='#1d1d1b'
            />
            <path
              id='Path_29'
              data-name='Path 29'
              d='M1393.551,658.854a1.423,1.423,0,0,1-.47,1.258,1.738,1.738,0,0,1-1.079.291l-.553-.028a3.144,3.144,0,0,1-2.006-.5,2.131,2.131,0,0,1-.594-1.714v-6.14q0-2.047-1.964-2.047a3.6,3.6,0,0,0-1.853.471v8.408a1.422,1.422,0,0,1-.471,1.258,1.736,1.736,0,0,1-1.078.291l-.554-.028a3.143,3.143,0,0,1-2-.5,2.129,2.129,0,0,1-.595-1.714V643.393a1.477,1.477,0,0,1,.416-1.244,1.777,1.777,0,0,1,1.078-.3l.609.028a3.145,3.145,0,0,1,2.005.5,2.13,2.13,0,0,1,.594,1.714V646.8a8.676,8.676,0,0,1,1.273-.346,7.21,7.21,0,0,1,1.438-.153,6.089,6.089,0,0,1,4.314,1.4,5.278,5.278,0,0,1,1.494,4.052Z'
              transform='translate(-1280.152 -637.448)'
              fill='#1d1d1b'
            />
            <path
              id='Path_30'
              data-name='Path 30'
              d='M1416.251,660.965a2.18,2.18,0,0,0,.789-.262v-2.213l-1.991.166q-1.771.11-1.771,1.161,0,1.244,1.88,1.245a6.159,6.159,0,0,0,1.092-.1m3.61-9.486a4.426,4.426,0,0,1,1.714,3.775v6.2a1.865,1.865,0,0,1-.318,1.093,3.176,3.176,0,0,1-1.037.872,9.793,9.793,0,0,1-5.062,1.079,8.33,8.33,0,0,1-4.743-1.148,3.815,3.815,0,0,1-1.673-3.361,3.745,3.745,0,0,1,1.134-2.932,6.112,6.112,0,0,1,3.512-1.272l3.624-.387V655.2q0-1.494-2.738-1.494a11.45,11.45,0,0,0-2.738.387,3.764,3.764,0,0,1-.8.138.971.971,0,0,1-.857-.456,1.971,1.971,0,0,1-.3-1.12,1.822,1.822,0,0,1,.318-1.134,2.694,2.694,0,0,1,1.093-.747,10.865,10.865,0,0,1,3.955-.581,8.1,8.1,0,0,1,4.923,1.286'
              transform='translate(-1293.408 -641.342)'
              fill='#1d1d1b'
            />
            <path
              id='Path_31'
              data-name='Path 31'
              d='M1212.781,653.873a1.514,1.514,0,0,1-.428.665,1.729,1.729,0,0,1-1.273.472,1.423,1.423,0,0,1-1.143-.354,7.357,7.357,0,0,1-1.264-1.5c-.9-1.234-2.5-2.984-3.519-4.32.021,1.242-.043,2.923-.028,4.072.007.595-.05.695-.317.972s-.5.2-.8.251a1.475,1.475,0,0,1-.39,0c-.5-.111-1.02.133-1.407-.353s-.426-.388-.467-1.365c-.161-3.753-.017-8.536-.076-12.347-.011-.716.039-.742.311-1.063s.5-.161.814-.234a1.34,1.34,0,0,1,.415.022c.524.115,1.031.007,1.426.481s.408.765.429,1.615c.026,1.077.019,2.945-.03,4.165,1.457-1.931,3.054-4.209,4.266-6.188.388-.633.5-.456.937-.642a.254.254,0,0,1,.23,0,2.113,2.113,0,0,1,1.284.47,1.433,1.433,0,0,1,.538,1.09,1.474,1.474,0,0,1-.139.991,9.244,9.244,0,0,1-.925,1.585c-.788,1.189-1.984,3.146-2.912,4.458.541.772,1.453,2.058,1.9,2.692s.346.472.739,1.013,1.052,1.431,1.467,1.969a2.646,2.646,0,0,1,.346.546.993.993,0,0,1,.091.461.818.818,0,0,1-.076.381m-13.339-.377c-.232.272-.451.168-.727.226a1.914,1.914,0,0,1-.4.011c-.486-.053-1,.069-1.369-.324s-.418-.487-.432-1.222c-.044-2.257,0-5.2-.048-7.485a1.237,1.237,0,0,1,.247-1c.222-.306.432-.235.724-.3a1.642,1.642,0,0,1,.4.009c.5.07,1.01-.021,1.378.408a1.645,1.645,0,0,1,.445,1.351c.054,2.259.016,5.164.056,7.424.01.567-.041.626-.273.9m-2.755-14.252a1.694,1.694,0,0,1,1.259-.466,1.713,1.713,0,0,1,1.254.5,1.685,1.685,0,0,1,.488,1.253,1.992,1.992,0,0,1-.472,1.328,1.53,1.53,0,0,1-1.239.573,1.633,1.633,0,0,1-1.276-.619,1.974,1.974,0,0,1-.5-1.314,1.625,1.625,0,0,1,.487-1.25m15.778-5.643-16.717,1.932a3.226,3.226,0,0,0-3.217,3.216v15.7a3.227,3.227,0,0,0,3.217,3.217l16.717,1.932a3.226,3.226,0,0,0,3.217-3.217V636.819a3.226,3.226,0,0,0-3.217-3.217'
              transform='translate(-1192.531 -633.602)'
              fill='#1d1d1b'
            />
          </svg>
        </IKLink>
        <div className='flex w-1/3 md:hidden'></div>
      </div>
    </nav>
  );
}
