export default function CartLineItemsLoading() {
  const renderLoadingItems = () => {
    return Array.from({ length: 2 }, (_, index) => (
      <li key={index} className='flex animate-pulse items-center py-1.5'>
        <div className='relative h-14 w-14 flex-shrink-0 rounded-md bg-gray-200'></div>
        <div className='ml-4 flex flex-1 flex-col'>
          <div className='flex'>
            <div className='min-w-0 flex-1'>
              <div className='mb-1 h-4 w-1/2 rounded bg-gray-200'></div>
              <div className='h-3 w-1/3 rounded bg-gray-200'></div>
            </div>
          </div>
        </div>
      </li>
    ));
  };

  return <ul>{renderLoadingItems()}</ul>;
}
