'use client';
import { InputField, SelectField } from '@/components/shared/form';
import { AddressFormType } from '@/types/types';
import * as yup from 'yup';
import { FormikProps, Formik } from 'formik';

const validationSchema = yup.object().shape({
  street: yup.string().required('Required*'),
  building: yup.string(),
  suburb: yup.string().required('Required*'),
  city: yup.string().required('Required*'),
  province: yup.string().required('Required*'),
  postCode: yup.string().required('Required*'),
});

interface Props {
  initialValues: AddressFormType;
  formActions: {
    onFormSubmit: (values: AddressFormType) => void;
    // Add other actions if needed
  };
  formRef: React.RefObject<FormikProps<AddressFormType>>;
}

export default function AddressForm({
  initialValues,
  formActions,
  formRef,
}: Props) {
  const handleSubmit = (values: AddressFormType) => {
    formActions.onFormSubmit(values);
  };

  return (
    <Formik<AddressFormType>
      innerRef={formRef}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <div className='mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4'>
        <div className='sm:col-span-2'>
          <InputField
            id='deliveryStreet'
            label='Street address'
            name='deliveryStreet'
            autoComplete='address'
            type='text'
            required={true}
            className={`input`}
            labelProps={{
              className: 'inputLabel',
            }}
          />
        </div>

        <div className='sm:col-span-2'>
          <InputField
            id='deliverySuburb'
            label='Suburb'
            name='deliverySuburb'
            type='text'
            required={true}
            className={`input`}
            labelProps={{
              className: 'inputLabel',
            }}
          />
        </div>

        <div className='sm:col-span-2'>
          <InputField
            id='deliveryCity'
            label='City'
            name='deliveryCity'
            type='text'
            autoComplete='city'
            required={true}
            className={`input`}
            labelProps={{
              className: 'inputLabel',
            }}
          />
        </div>

        <div>
          <SelectField
            field={{
              label: 'Province',
              name: 'deliveryProvince',
              fieldType: 'select',
              description: '',
              hidden: false,
              helpText: '',
              options: [
                {
                  value: 'Eastern Cape',
                  label: 'Eastern Cape',
                },
                {
                  value: 'Free State',
                  label: 'Free State',
                },
                {
                  value: 'Gauteng',
                  label: 'Gauteng',
                },
                {
                  value: 'KwaZulu-Natal',
                  label: 'KwaZulu-Natal',
                },
                {
                  value: 'Limpopo',
                  label: 'Limpopo',
                },
                {
                  value: 'Mpumalanga',
                  label: 'Mpumalanga',
                },
                {
                  value: 'Northern Cape',
                  label: 'Northern Cape',
                },
                {
                  value: 'North West',
                  label: 'North West',
                },
                {
                  value: 'Western Cape',
                  label: 'Western Cape',
                },
              ],
              variant: 'outlined',
              required: true,
              placeholder: '',
              objectTypeId: '',
            }}
            name={'province'}
            required={true}
            type={'select'}
            className={`input`}
            labelProps={{
              className: 'inputLabel',
            }}
          />
        </div>

        <div>
          <InputField
            id='deliveryPostalCode'
            label='Postal code'
            name='deliveryPostalCode'
            type='text'
            autoComplete='postal-code'
            required={true}
            maxLength={4}
            minLength={4}
            className={`input`}
            labelProps={{
              className: 'inputLabel',
            }}
          />
        </div>
      </div>
    </Formik>
  );
}
