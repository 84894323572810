import { noParagraphs } from '../sections/PortableTextComponents';
import { IKButton } from '../shared/material';
import { Toast } from '@/types/types';
import { PortableText } from '@portabletext/react';
import blocksToText from '@/sanity/utils/shopify/blocksToText';

export default function ToastContent({ toast }: { toast: Partial<Toast> }) {
  // @ts-ignore
  const cardTitle = `Toast - ${typeof toast.message === 'string' ? toast.message : blocksToText(toast.message)}`;
  return (
    <div className='flex items-center justify-between gap-5'>
      <div>
        <span className='text-sm font-medium text-white'>
          {toast.message &&
            (typeof toast.message === 'string' ? (
              toast.message
            ) : (
              <PortableText value={toast.message} components={noParagraphs} />
            ))}
        </span>
      </div>
      <div className='ml-auto flex-shrink-0 pr-3'>
        {toast?.button?.text && (
          <IKButton
            cardTitle={cardTitle}
            link={toast.button.link}
            size='sm'
            color='light-blue'
            className='!bg-teal-50 !px-4 !py-2 !text-lg !text-charcoal md:!text-base'
          >
            <span>{toast.button.text}</span>
          </IKButton>
        )}
      </div>
      <div className='absolute'></div>
    </div>
  );
}
