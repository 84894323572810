import Image from 'next/image';
import { cartAtom } from '@/store/cartStore';
import { useAtomValue } from 'jotai';
import CartLineItemsLoading from './CartLineItemsLoading';
export default function CartLineItems() {
  const cart = useAtomValue(cartAtom);

  return (
    <>
      <h3 className='sr-only'>Items in your cart</h3>
      {cart === null && <CartLineItemsLoading />}
      <ul>
        {cart?.lineItems.map((lineItem) => (
          <li
            key={lineItem.product.variantGid}
            className='flex items-center py-2'
          >
            <div className='relative h-14 w-14 flex-shrink-0 rounded-md bg-yellow'>
              <Image
                src={lineItem.product.previewImageUrl}
                alt={lineItem.product.title}
                width={56}
                height={56}
                className='h-14 w-14 rounded-md'
              />
              <span className='absolute -right-2 top-1 rounded-full bg-teal px-2 py-1 text-xs font-semibold text-white'>
                {lineItem.quantity}
              </span>
            </div>

            <div className='ml-4 flex flex-1 flex-col'>
              <div className='flex'>
                <div className='min-w-0 flex-1'>
                  <h4 className='text-md font-bold'>
                    {lineItem.product.title}
                  </h4>
                  <p className='text-sm capitalize'>
                    {lineItem.product.categorySlug.replace(/-/g, ' ')}
                  </p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
