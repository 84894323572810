'use client';
import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { Info, X } from '@phosphor-icons/react';

export default function DeliveryBanner() {
  const [show, setShow] = useState(true);

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div aria-live='assertive'>
        <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter='transform ease-out duration-300 transition'
            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='pointer-events-auto w-full overflow-hidden rounded-lg bg-teal-50 ring-1 ring-teal ring-opacity-5'>
              <div className='p-4'>
                <div className='flex items-center md:items-start'>
                  <div className='flex-shrink-0'>
                    <Info
                      weight='regular'
                      className='h-6 w-6 text-teal'
                      aria-hidden='true'
                    />
                  </div>
                  <div className='ml-3 w-0 flex-1 pt-0.5'>
                    <p className='text-sm text-charcoal'>
                      Please provide your business address to speed up delivery.
                    </p>
                  </div>
                  <div className='ml-4 flex flex-shrink-0'>
                    <button
                      type='button'
                      className='inline-flex rounded-md text-charcoal hover:text-charcoal-900 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2'
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className='sr-only'>Close</span>
                      <X
                        weight='regular'
                        className='h-5 w-5'
                        aria-hidden='true'
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
